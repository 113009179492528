/**
 * main.js
 *
 * Main Javascript Functions
 */


jQuery(document).ready(function($) {
    var resizeTimer;

    var offset = 300,
        offset_opacity = 900,
        scroll_top_duration = 700,
        $back_to_top = $('.sgcc-totop');

    var body = $('body');

    var navPost = $('.post-navigation');

    var navPostPos = 0;

    /*
        Function to wait for element load and apply something next
     */
    function applyWhenElementExists(selector, myFunction, intervalTime) {
        var interval = setInterval(function() {
            if (jQuery(selector).length > 0) {
                myFunction();
                clearInterval(interval);
            }
        }, intervalTime);
    }


    /*
        Header Weather Menu : 5 days forecast
     */
    applyWhenElementExists('.sgcc-header-weather .now', function() {
        $('.sgcc-header-weather .now').hover(function() {
            if ($('.sgcc-header-weather #wpc-weather .forecast').hasClass('open')) {
                $('.sgcc-header-weather #wpc-weather .forecast').removeClass('open');
            } else {
                $('.sgcc-header-weather #wpc-weather .forecast').addClass('open');
            }
            if ($('.secondary-navigation').hasClass('weather-open')) {
                $('.secondary-navigation').removeClass('weather-open');
            } else {
                $('.secondary-navigation').addClass('weather-open');
            }
        });
    }, 50);


    /*
        GreenKeeper panel
     */
    applyWhenElementExists('[class^=gkper__]', function() {
		if (jQuery('[class^=wpgb-facet]').length > 0 || jQuery('[class^=wp-grid]').length > 0 || jQuery('[class^=sgcc-album-photo]').length > 0) {
			$('.gkper-panel').hide();
			return;
		}
        $('#gkper-slide').click(function() {
            $('.gkper-panel').toggleClass('gkper-panel--is-visible');
            return false;
        });
        $(':not(.gkper-panel):not(#gkper-slide)').click(function() {
            $('.gkper-panel').removeClass('gkper-panel--is-visible');
        });
    }, 50);


	/*
        h2 Styles (those added in wysiwyg editor) with duplicated text
     */

    $('h2').each(function() {

        $(this).attr('data-attr', $($(this).contents()[0]).text());

    });



    /*
        Facebook Review Pro users images bigger
     */

    $('.section-shortcode .wp-fbrev .wp-facebook-left img').each(function() {

        $grav = $(this).attr('src');
        $(this).attr('src', $grav + '?type=large');

    });



    /*
        Hole Select Menu Display
     */

    $('#hole-data__sellnk').click(function() {
        $(this).toggleClass('holsel--closed');
        return false;
    });



    /*
        Smooth Link Back To Top
     */

    $back_to_top.on('click', function(event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0,
        }, scroll_top_duration);
    });



    /*
        Post Navigation Clic Animation
        Also custom Golf Course Navigation Animation
     */

    $('.post-navigation .nav-previous a, .post-navigation .nav-next a, .hole-data__select li a').click(function() {
        if ($('.hole-data__select ul').length) {
            $('#hole-data__sellnk').addClass('holsel--closed');
        }
        $('.site-main .post-navigation.course-navigation').css({ 'overflow': 'hidden' });
        $(this).closest('.nav-links').addClass('out-of-screen');
    });



    /*
        Adding smooth scroll on anchors
     */

    $('a.smooth-anchlnk[href*="#"]:not([href="#"])').click(function() {
        if (
            location.hostname == this.hostname &&
            this.pathname.replace(/^\//, "") == location.pathname.replace(/^\//, "")
        ) {
            if ($('.course-navigation').hasClass('postnav-is-sticky')) {
                $('html, body').animate({ scrollTop: 0 }, 1500);
            } else {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) + "]");
                if (anchor.length) {
                    $("html, body").animate({ scrollTop: anchor.offset().top }, 1500);
                }
            }
        }
        return false;
    });


    /*
        FAQ
     */

    var allPanels = $('.faq-cat__grqr__answ').hide();

    $('.faq-cat__grqr__quest').click(function() {
        $this = $(this);
        $target = $this.next('.faq-cat__grqr__answ');

        if (!$target.hasClass('active')) {
            allPanels.removeClass('active').slideUp();
            $target.addClass('active').slideDown();
        } else {
            $target.removeClass('active').slideUp();
        }
    });



    /*
        Remove custom styles for external buttons by adding css class
     */

    applyWhenElementExists('.gm-style button, .wpgb-lightbox-holder button', function() {
        $('.gm-style button, .wpgb-lightbox-holder button').addClass('btn--nosgcc');
    }, 50);


    /* Facebook review pro Links cancellation since Facebook forbit it
     */
    $('a.wp-facebook-name').click(function() {
        return false;
    });


    /*
        Remove custom styles for external links by adding css class
     */

    applyWhenElementExists('.wp-facebook-list a, .wp-grid-builder a', function() {
        $('.wp-facebook-list a, .wp-grid-builder a').addClass('link--noway');
    }, 50);



    /*
        Magnific popup : Booking
     */

    $('.book a, .sgcc-booking, .booking-resp').magnificPopup({
        type: 'ajax',
        callbacks: {
            parseAjax: function(mfpResponse) {
                if (this.st.ajax.settings.id != 'sg-book-all') {
                    mfpResponse.data = $(mfpResponse.data).find('#' + this.st.ajax.settings.id);
                } else {
                    mfpResponse.data = $(mfpResponse.data);
                }
            },
            elementParse: function(item) {
                this.st.ajax.settings = item.el.data();
            },
            ajaxContentAdded: function() {

                // Ajax content is loaded and appended to DOM
                // Javascript needed in popup MUST be added HERE


                // This is used to send child number (booking form) to Reservit
                $('#nbchd').on('change', function() {

                    nb_child = this.value;

                    $('#age_child').replaceWith('<div id="age_child"></div>');

                    for (i = 0; i < nb_child; i++) {
                        $('<input type="hidden" name="ages' + (i + 1) + '" id="ages' + (i + 1) + '" value="12">').appendTo("#age_child");
                    }
                });

                // What language ?
                var sg_language = jQuery('.sgb-language').attr('value');
                sg_language = sg_language.toLowerCase();


                // This is used to send date in several parts to Reservit
                if (jQuery('#arrival_date').length) {
                    jQuery('#arrival_date').datepicker(
                        $.extend({},
                            jQuery.datepicker.regional[sg_language], {
                                minDate: 0,
                                maxDate: '+5Y',
                                dateFormat: 'dd/mm/yy',
                                onSelect: function(dateText, inst) {
                                    var str = dateText;
                                    var exploded = str.split('/');
                                    jQuery('#form_resa').find('#fday').val(exploded[0]);
                                    jQuery('#form_resa').find('#fmonth').val(exploded[1]);
                                    jQuery('#form_resa').find('#fyear').val(exploded[2]);

                                    var newDate = jQuery(this).datepicker('getDate');
                                    if (newDate) { // Not null
                                        newDate.setDate(newDate.getDate() + 1);
                                    }
                                    jQuery('#departure_date').datepicker('option', 'minDate', newDate);
                                }
                            }
                        )
                    );

                    jQuery('#departure_date').datepicker(
                        $.extend({},
                            jQuery.datepicker.regional[sg_language], {
                                minDate: 0,
                                maxDate: '+5Y',
                                dateFormat: 'dd/mm/yy',
                                onSelect: function(dateText, inst) {
                                    var str = dateText;
                                    var exploded = str.split('/');
                                    jQuery('#form_resa').find("#tday").val(exploded[0]);
                                    jQuery('#form_resa').find("#tmonth").val(exploded[1]);
                                    jQuery('#form_resa').find("#tyear").val(exploded[2]);

                                }
                            }
                        )
                    );
                }
            }
        }
    });


    /*
        Magnific popup : Search
     */

    $('.btn-search-display').magnificPopup({
        type: 'inline',
        focus: 'input',
        closeOnBgClick: 'true',
        showCloseBtn: 'true',
        mainClass: 'mfp-fade',
        midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });



    /*
        Custom Functions with positionned elements
        ==========================================
     */


    /*
        Responsive languages switcher
     */

    switchRespLanguages();

    function switchRespLanguages() {
        if ($(window).width() <= 960) {

            $('.current-lang>a').click(function() {
                return false;
            });
        }
    }


    /*
        Hide or display link To Top
     */

    topTopVisibility();

    function topTopVisibility() {
        ($(this).scrollTop() > offset) ? $back_to_top.addClass('totop--is-visible'): $back_to_top.removeClass('totop--is-visible totop--fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('totop--fade-out');
        }
    }


    /*
        Post Navigation Position
     */

    navPostPosition();

    function navPostPosition() {
        if (navPost.length) {
            navPostPos = (navPost.offset().top - 44);
        }
	}
	

    /*
        Shortcodes Columns
     */

    shortcodeColumns();

    function shortcodeColumns() {
		document.querySelectorAll('.shortcode-column').forEach(function(col) {
			const breakpoint = col.closest('.section-shortcode-columns').dataset.breakpoint;
			if (col) {
				col.style.maxWidth = col.style.flexBasis = (breakpoint !== '' && parseInt(breakpoint) > window.innerWidth) ? '100%' : col.dataset.width + '%';
			}
		});
	}

	locationsMapResize();
	
	function locationsMapResize() {
		const map = document.querySelector('#sgcc-holiday-rental-locations>div');
		const grid = document.querySelector('#sgcc-holiday-rental-locations>aside');
		const breakpoint = (map ) ? map.closest('.section-shortcode-columns').dataset.breakpoint : false;

		if (map && grid && breakpoint && window.innerWidth > parseInt(breakpoint) && window.innerWidth < 1920  ) {
			map.style.maxWidth = map.style.flexBasis = '45%';
			grid.style.maxWidth = grid.style.flexBasis = '55%';
		}
	}


    /*
        Main navigation, Posts navigation : when sticky
     */

    navPostSticky();

    function navPostSticky() {

        var scroll = $(window).scrollTop();

        // Sticky Main Menu
        if ($(window).scrollTop() > 200) {
            if (!body.hasClass('nav-is-sticky')) {
                $('.header-content').css({ 'opacity': '0' });
                body.addClass('nav-is-sticky');
                $('.header-content').animate({ 'opacity': '1' }, 500);
            }
        } else {
            if (body.hasClass('nav-is-sticky')) {
                $('.header-content').css({ 'opacity': '0' });
                body.removeClass('nav-is-sticky');
                $('.header-content').animate({ 'opacity': '1' }, 500);
            }
        }
        // Sticky Post Navigation
        if (navPost.length) {
            if (!$('.header-wrapper>img').length) {
                navPostPos = $('.header-wrapper').height();
            }
            if ($(window).scrollTop() > navPostPos) {
                if (!navPost.hasClass('postnav-is-sticky')) {
                    navPost.css({ 'opacity': '0' });
                    navPost.addClass('postnav-is-sticky');
                    navPost.animate({ 'opacity': '1' }, 500);
                }
            } else {
                if (navPost.hasClass('postnav-is-sticky')) {
                    navPost.css({ 'opacity': '0' });
                    navPost.removeClass('postnav-is-sticky');
                    navPost.animate({ 'opacity': '1' }, 500);
                }
            }
        }

    }


    /*
        Window Resize
        ==========================================
     */


    $(window).on('resize', function(e) {


        clearTimeout(resizeTimer);
        // Run code only when resizing has "stopped"
        resizeTimer = setTimeout(function() {


            /*
                Responsive languages switcher
             */

            switchRespLanguages();


            /*
                Hide or display link To Top
             */

            topTopVisibility();


            /*
                Post Navigation Position
             */

			navPostPosition();
			

			/*
        		Shortcodes Columns
			 */
			 
			shortcodeColumns();
			locationsMapResize();

        }, 250);

    });


    /*
        Window Scroll
        ==========================================
     */

    $(window).scroll(function() {

        /*
            Hide or display link To Top
         */

        topTopVisibility();


        /*
            Main navigation, Posts navigation : when sticky
        */

        navPostSticky();

    });




});